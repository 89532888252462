<!-- 供应商供货表-详情 -->
<template>
  <div class="pagePadding home">
    <Form inline class="formMarginBtm20">
      <!-- 产品编号 -->
      <!-- <FormItem>
        <span>产品编号：</span>
        <Select placeholder="请输入" v-model="queryFrom.product_model_code" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in otherSelect" :value="item.product_model_code" :key="index">{{ item.product_model_code }}</Option>
        </Select>
      </FormItem> -->
      <!-- 产品名称 -->
      <FormItem class="marginLeft25">
        <span>产品名称：</span>
        <!-- <Select placeholder="请选择" v-model="queryFrom.product_id" clearable filterable class="iviewIptWidth250" @on-change="getOtherSelect" @on-clear="clearOtherItem">
          <Option v-for="(item, index) in productSelect" :value="item.product_id" :key="index">{{ item.product_name }}</Option>
        </Select> -->
        <Select placeholder="请选择" v-model="queryFrom.product_id" @on-change="productChange" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in productSelect" :value="item.product_id" :key="index">{{ item.product_name }}</Option>
        </Select>
      </FormItem>
      <!-- 规格型号 -->
      <!-- <FormItem class="marginLeft60">
        <span>规格型号：</span>
        <Select placeholder="请选择" v-model="queryFrom.code_id" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in otherSelect" :value="item.code_id" :key="index">{{ item.model_name }}</Option>
        </Select>
      </FormItem> -->
      <!-- 货号/SKU -->
      <FormItem class="marginLeft60">
        <span>规格/SKU：</span>
        <!-- <Select placeholder="请选择" v-model="queryFrom.item_number" clearable filterable :disabled="productDisable" class="iviewIptWidth250">
          <Option v-for="(item, index) in otherSelect" :value="item.item_number" :key="index">{{ item.model_name + '/' + item.item_number }}</Option>
        </Select> -->
        <Select placeholder="请选择" v-model="queryFrom.specification_id_str" :disabled="!queryFrom.product_id" clearable filterable multiple :max-tag-count="1" class="iviewIptWidth250">
          <Option v-for="(item, index) in skuList" :value="item.specification_id" :key="index" :label="item.model_name + '-' + item.item_number">
            <span>{{ item.model_name }}</span>
            <span style="float: right; color: #ccc; margin-right: 20px;">{{ item.item_number }}</span>
          </Option>
        </Select>
      </FormItem>
      <!-- 按钮 -->
      <FormItem class="po-create-number marginRight0" :label-width="10">
        <span class="pageBtn finger btnSure" @click="searchItem">查询</span>
        <span class="pageBtn finger btnSure marginLeft20">
          <span v-if="!Loagin" class="pageBtn finger btnSure" @click="Export">导出</span>
          <span v-if="Loagin" class="pageBtn finger btnSure">
            <Spin v-if="Loagin" style="display: inline-block; color: white;">
              <Icon type="ios-loading" class="demo-spin-icon-load"></Icon>
            </Spin>
          </span>
        </span>
        <span class="pageBtn finger btnSure marginLeft20" @click="setupStatus = true">设置</span>
        <span class="pageBtn finger btnReset marginLeft20" @click="goback">返回</span>
      </FormItem>
    </Form>
    <!-- <Table :columns="listColumns" :data="listData" border show-summary :summary-method="handleSummary" class="table"></Table> -->
    <div class="tabelDiv tabDiv" ref="tabDiv">
      <selfTable :height="tableHeight" :productList="listColumns" :productData="listData" :option_page="option_page" border show-summary :summary-method="handleSummary" class="table" :loading="loading" :isLoad="loading">
        <template slot-scope="{ row, index }" slot="do">
          <div><span class="finger color389" @click="gotoThirdDetail(row, index)">详情</span></div>
        </template>
      </selfTable>
    </div>
    <template v-if="listData.length != 0">
      <div class="marginTop20">
        <Page v-if="total <= 60" :total="total" :current="pages.page" @on-change="changePage" class="fl page" />
        <Page v-if="total > 60" show-elevator :total="total" :current="pages.page" @on-change="changePage" class="fl page" />
        <div class="fr totalFont page">共计{{ total }}条记录</div>
      </div>
    </template>
    <!-- 二级详情弹窗 -->
    <Modal v-model="popup" :closable="false" footer-hide width="80">
      <Table :columns="popupListColumns" :data="popupListData" border show-summary :summary-method="handleSummary" class="table"></Table>
    </Modal>

    <!-- 动态表头弹窗 -->
    <table-setup v-if="setupStatus" :pageList="titleList" :option_page="option_page" @cancleBtn="setupStatus = false" @sureBrn="sureSetup"></table-setup>
  </div>
</template>

<script>
import selfTable from '@/components/table'
import TableSetup from '@/components/tableSetup'
export default {
  name: 'detailsSupplierSupplyListConsign',
  components: {
    selfTable,
    TableSetup,
  },
  data() {
    return {
      Loagin: false,
      tableHeight: 0,
      titleList: [],
      option_page: '28',
      setupStatus: false,
      skuList: [],
      // 二级详情弹窗
      popup: false,
      popupListData: [],
      // 二级详情表头
      popupListColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          minWidth: 75,
          maxWidth: 90,
          key: 'index',
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          minWidth: 300,
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          width: 116,
        },
        {
          title: '货号/SKU',
          key: 'item_number',
          align: 'center',
          width: 116,
        },
        {
          title: '批号',
          key: 'batch_number',
          align: 'center',
          width: 116,
        },
        {
          title: '采购订单号',
          key: 'd',
          align: 'center',
          //width: 116,
          width: 150,
        },
        {
          title: '采购数量',
          key: 'purchase_quantity',
          align: 'center',
          width: 176,
        },
        {
          title: '采购总价',
          render: (h, param) => {
            let num = param.row.purchase_amount
            num = parseFloat((num + '').replace(/[^\d\.-]/g, '')).toFixed(2) + ''
            var valueArr = num.split('.')[0].split('').reverse()
            const valueFloat = num.split('.')[1]
            let valueString = ''
            for (let i = 0; i < valueArr.length; i++) {
              valueString += valueArr[i] + ((i + 1) % 3 == 0 && i + 1 != valueArr.length ? ',' : '') // 循环 取数值并在每三位加个','
            }
            const money = valueString.split('').reverse().join('') + '.' + valueFloat // 拼接上小数位
            return h('span', '￥' + money)
          },
          key: 'purchase_amount',
          align: 'center',
          width: 176,
        },
      ],
      // 表格数据
      listData: [],
      // 表格头部标题
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          minWidth: 75,
          key: 'index',
        },
        {
          title: '产品编号',
          key: 'product_model_code',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          minWidth: 250,
        },
        {
          title: '基本单位',
          key: 'unit',
          align: 'center',
          minWidth: 80,
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '货号/SKU',
          key: 'item_number',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '厂商',
          key: 'factory_name',
          align: 'center',
          minWidth: 250,
        },
        {
          title: '采购数量',
          key: 'purchase_quantity',
          align: 'center',
          minWidth: 80,
        },
        {
          title: '采购金额',
          key: 'purchase_amount',
          minWidth: 80,
          // render: (h, param) => {
          //   let num = param.row.purchase_amount
          //   num = parseFloat((num + '').replace(/[^\d\.-]/g, '')).toFixed(2) + ''
          //   var valueArr = num.split('.')[0].split('').reverse()
          //   const valueFloat = num.split('.')[1]
          //   let valueString = ''
          //   for (let i = 0; i < valueArr.length; i++) {
          //     valueString += valueArr[i] + ((i + 1) % 3 == 0 && i + 1 != valueArr.length ? ',' : '') // 循环 取数值并在每三位加个','
          //   }
          //   const money = valueString.split('').reverse().join('') + '.' + valueFloat // 拼接上小数位
          //   return h('span', '￥' + money)
          // },
          align: 'center',
        },
        {
          title: '销售数量',
          key: 'sale_quantity',
          align: 'center',
          minWidth: 80,
        },
        {
          title: '销售金额',
          minWidth: 80,
          // render: (h, param) => {
          //   let num = param.row.sale_amount
          //   num = parseFloat((num + '').replace(/[^\d\.-]/g, '')).toFixed(2) + ''
          //   var valueArr = num.split('.')[0].split('').reverse()
          //   const valueFloat = num.split('.')[1]
          //   let valueString = ''
          //   for (let i = 0; i < valueArr.length; i++) {
          //     valueString += valueArr[i] + ((i + 1) % 3 == 0 && i + 1 != valueArr.length ? ',' : '') // 循环 取数值并在每三位加个','
          //   }
          //   const money = valueString.split('').reverse().join('') + '.' + valueFloat // 拼接上小数位
          //   return h('span', '￥' + money)
          // },
          key: 'sale_amount',
          align: 'center',
        },
        // {
        //   title: '操作',
        //   align: 'center',
        //   minWidth: 150,
        //   maxWidth: 200,
        //   render: (h, param) => {
        //     return h('div', [
        //       h(
        //         'span',
        //         {
        //           style: {
        //             color: '#389AFC',
        //             fontSize: '14px',
        //             cursor: 'pointer',
        //           },
        //           on: {
        //             click: () => {
        //               let obj = {}
        //               for (const k in param.row) {
        //                 if (param.row.hasOwnProperty(k)) {
        //                   const element = param.row[k]
        //                   this.$set(obj, k, element)
        //                 }
        //               }
        //               this.popupListData = [obj]
        //               console.log(this.popupListData)
        //               this.popup = true
        //             },
        //           },
        //         },
        //         '详情'
        //       ),
        //     ])
        //   },
        // },
      ],
      pages: {
        page: 1,
        rows: 10,
      },
      total: 0,
      // 表单拉下选项
      thirdList: [],
      // 查询参数
      queryFrom: {
        product_model_code: '',
        product_id: '',
        code_id: '',
        item_number: '',
      },
      supplier_id: '', // 产品id
      productSelect: [], // 产品名称下拉框
      otherSelect: [], // 其他项下来框
      productDisable: true, // 规格禁用
      // 暂存采购金额总合
      totalPurchaseAmount: 0,
      totalPurchaseQuantity: 0,
      totalSaleQuantity: 0,
      totalSaleAmount: 0,
      loading: true,
    }
  },
  methods: {
    // 供应商二级页面导出
    Export() {
      if (this.Loagin) {
        return
      }
      this.Loagin = true
      let list = this.queryFrom.specification_id_str ? this.queryFrom.specification_id_str.join(',') : ''
      let data = {
        supplier_id: this.$route.query.supplier_id,
        product_id: this.queryFrom.product_id,
        specification_id_str: list,
      }
      this.$http.downFile(this.$apiConsign.getExportsupplier_supplySecond, data, false).then(res => {
        let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '供应商供货表详情表.xlsx'
        aLink.click()
        this.Loagin = false
      })
    },
    gotoThirdDetail(row, index) {
      console.log(row)
      this.$router.push({
        path: '/detailsThirdSupplierSupplyListConsign',
        query: {
          product_model_code: row.product_model_code,
          model_id: row.model_id,
        },
      })
    },
    // 处理金额
    OpearateMoney(item) {
      let num = item
      num = parseFloat((num + '').replace(/[^\d\.-]/g, '')).toFixed(2) + ''
      var valueArr = num.split('.')[0].split('').reverse()
      const valueFloat = num.split('.')[1]
      let valueString = ''
      for (let i = 0; i < valueArr.length; i++) {
        valueString += valueArr[i] + ((i + 1) % 3 == 0 && i + 1 != valueArr.length ? ',' : '') // 循环 取数值并在每三位加个','
      }
      const money = valueString.split('').reverse().join('') + '.' + valueFloat // 拼接上小数位
      return '¥' + money
    },
    sureSetup(val) {
      this.queryOption()
    },
    // 获取表格表头
    queryOption() {
      this.$http
        .get(this.$apiConsign.systemOptionItem, { option_page: this.option_page })
        .then(res => {
          this.titleList = res.data.enable
          localStorage.setItem('adminTtitleList', JSON.stringify(res.data.list))
          for (let i = 0; i < this.titleList.length; i++) {
            if (this.titleList[i].show) {
              this.titleList[i].key = this.titleList[i].data_col
              this.titleList[i].width = this.titleList[i].column_width
              this.titleList[i].align = 'center'
              this.titleList[i].resizable = true
            }
          }
          this.listColumns = []
          this.listColumns.unshift({
            title: '序号',
            type: 'index',
            align: 'center',
            width: 60,
          })
          this.listColumns.push({
            title: '操作',
            align: 'center',
            width: 100,
            fixed: 'right',
            slot: 'do',
          })
          this.listColumns = [...this.listColumns, ...this.titleList]
          this.listColumns = JSON.parse(JSON.stringify(this.listColumns))
        })
        .then(() => {
          // this.queryStoreList()
        })
    },
    // 产品编号改变
    productChange(e) {
      console.log(e, 'e')
      this.skuList = []
      this.queryFrom.specification_id_str = ''
      if (!e) return
      // 规格/SKU下拉
      let data = {
        supplier_id: this.$route.query.supplier_id,
        product_id: e,
      }
      this.$http.get(this.$apiConsign.productSkuList, data).then(res => {
        this.skuList = res.data
      })
    },
    changePage(e) {
      console.log(e)
      let list = this.queryFrom.specification_id_str ? this.queryFrom.specification_id_str.join(',') : ''
      this.pages.page = e
      let searchForm = {}
      searchForm.page = this.pages.page
      searchForm.rows = this.pages.rows
      searchForm.supplier_id = this.$route.query.supplier_id
      searchForm.product_model_code = this.queryFrom.product_model_code
      searchForm.product_id = this.queryFrom.product_id
      searchForm.model_id = this.queryFrom.code_id
      searchForm.item_number = this.queryFrom.item_number
      searchForm.specification_id_str = list
      this.getTableList(searchForm)
    },
    // 表格的合计
    handleSummary({ columns, data }) {
      console.log(columns, 'columns')
      // console.log(data, 'data')
      const sums = {}
      columns.forEach((column, index) => {
        const key = column.key
        if (index === 0) {
          sums[key] = {
            key,
            value: '合计',
          }
        } else if (key === 'purchase_amount') {
          sums[key] = {
            key,
            value: '¥' + this.totalPurchaseAmount,
          }
        } else if (key === 'purchase_quantity') {
          sums[key] = {
            key,
            value: this.totalPurchaseQuantity,
          }
        } else if (key === 'sale_quantity') {
          sums[key] = {
            key,
            value: this.totalSaleQuantity,
          }
        } else if (key === 'sale_amount') {
          sums[key] = {
            key,
            value: '¥' + this.totalSaleAmount,
          }
        } else {
          sums[key] = {
            key,
            value: '',
          }
        }
        // const values = data.map(item => Number(item[key]))
        // if (!values.every(value => isNaN(value))) {
        //   const v = values.reduce((prev, curr) => {
        //     const value = Number(curr)
        //     if (!isNaN(value)) {
        //       return prev + curr
        //     } else {
        //       return prev
        //     }
        //   }, 0)
        //   sums[key] = {
        //     key,
        //     value: v,
        //   }
        // } else {
        //   sums[key] = {
        //     key,
        //     value: '',
        //   }
        // }
        // const values = data.map(item => Number(item[key]))
        // if (!values.every(value => isNaN(value))) {
        //   if (key === 'sale_amount') {
        //     let v = values.reduce((prev, curr) => {
        //       const value = Number(curr)
        //       if (!isNaN(value)) {
        //         return prev + curr
        //       } else {
        //         return prev
        //       }
        //     }, 0)
        //     v = this.formatMoney(v)
        //     sums[key] = {
        //       key,
        //       value: '￥' + v,
        //     }
        //   }
        //   if (key === 'purchase_quantity') {
        //     let v = values.reduce((prev, curr) => {
        //       const value = Number(curr)
        //       if (!isNaN(value)) {
        //         return prev + curr
        //       } else {
        //         return prev
        //       }
        //     }, 0)
        //     sums[key] = {
        //       key,
        //       value: v,
        //     }
        //   }
        //   if (key === 'sale_quantity') {
        //     let v = values.reduce((prev, curr) => {
        //       const value = Number(curr)
        //       if (!isNaN(value)) {
        //         return prev + curr
        //       } else {
        //         return prev
        //       }
        //     }, 0)
        //     sums[key] = {
        //       key,
        //       value: v,
        //     }
        //   }
        //   if(key==='purchase_amount'){
        //     sums[key] = {
        //       key,
        //       value: '1',
        //     }
        //   }
        //   if (key !== 'purchase_amount' && key !== 'purchase_quantity' && key !== 'sale_amount' && key !== 'sale_quantity') {
        //     sums[key] = {
        //       key,
        //       value: '',
        //     }
        //   }
        // } else {
        //   sums[key] = {
        //     key,
        //     value: '',
        //   }
        // }
      })
      return sums
    },
    // 获取表格数据
    getTableList(item) {
      this.loading = true
      this.$http.get(this.$apiConsign.supplierSupplyDetailList, item, true).then(res => {
        if (res.status) {
          this.listData = res.data.result
          this.totalPurchaseAmount = res.data.total_purchase_amount
          this.totalPurchaseQuantity = res.data.total_purchase_quantity
          this.totalSaleQuantity = res.data.total_sale_quantity
          this.totalSaleAmount = res.data.total_sale_amount
          this.loading = false
          this.listData.forEach((item, index) => {
            // this.totalPurchaseAmount = item.purchase_amount + this.totalPurchaseAmount
            // this.totalPurchaseQuantity = item.purchase_quantity + this.totalPurchaseQuantity
            // this.totalSaleQuantity = item.sale_quantity + this.totalSaleQuantity
            // this.totalSaleAmount = item.sale_amount + this.totalSaleAmount
            item.insert_time = item.insert_time ? this.$moment.unix(item.insert_time).format('YYYY-MM-DD') : ''
            item.licence_valid_period = item.licence_valid_period ? this.$moment.unix(item.licence_valid_period).format('YYYY-MM-DD') : ''
            item.production_date = item.production_date ? this.$moment.unix(item.production_date).format('YYYY-MM-DD HH:mm:ss') : ''
            // 判断是录入的是年月还是年月日
            item.production_date = item.production_date ? this.$utils.Timetransformation(item.production_date) : ''
            item.valid_period = item.valid_period ? this.$moment.unix(item.valid_period).format('YYYY-MM-DD HH:mm:ss') : ''
            item.valid_period = item.valid_period ? this.$utils.Timetransformation(item.valid_period) : ''
            item.production_license_valid_period = item.production_license_valid_period ? this.$moment.unix(item.production_license_valid_period).format('YYYY-MM-DD') : ''
            item.production_record_date = item.production_record_date ? this.$moment.unix(item.production_record_date).format('YYYY-MM-DD') : ''
            if (item.business_license_valid_period && this.$moment.unix(item.business_license_valid_period).format('YYYY-MM-DD') != '9999-12-31') {
              item.business_license_valid_period = item.business_license_valid_period ? this.$moment.unix(item.business_license_valid_period).format('YYYY-MM-DD') : ''
            } else if (item.business_license_valid_period && this.$moment.unix(item.business_license_valid_period).format('YYYY-MM-DD') == '9999-12-31') {
              item.business_license_valid_period = '长期'
            } else {
              item.business_license_valid_period = ''
            }
            if (item.record_valid_period && this.$moment.unix(item.record_valid_period).format('YYYY-MM-DD') != '9999-12-31') {
              item.record_valid_period = item.record_valid_period ? this.$moment.unix(item.record_valid_period).format('YYYY-MM-DD') : ''
            } else if (item.record_valid_period && this.$moment.unix(item.record_valid_period).format('YYYY-MM-DD') == '9999-12-31') {
              item.record_valid_period = '长期'
            } else {
              item.record_valid_period = ''
            }
            item.return_time = item.return_time ? this.$moment.unix(item.return_time).format('YYYY-MM-DD') : ''
            item.receive_time = item.receive_time ? this.$moment.unix(item.receive_time).format('YYYY-MM-DD') : ''
            item.accept_time = item.accept_time ? this.$moment.unix(item.accept_time).format('YYYY-MM-DD') : ''
            item.rec_time = item.rec_time ? this.$moment.unix(item.rec_time).format('YYYY-MM-DD') : ''
            item.order_time = item.order_time ? this.$moment.unix(item.order_time).format('YYYY-MM-DD') : ''
            item.picking_time = item.picking_time ? this.$moment.unix(item.picking_time).format('YYYY-MM-DD') : ''
            item.delivery_time = item.delivery_time ? this.$moment.unix(item.delivery_time).format('YYYY-MM-DD') : ''
            item.purchase_amount = this.OpearateMoney(item.purchase_amount)
            item.sale_amount = this.OpearateMoney(item.sale_amount)
          })
          this.$nextTick(() => {
            this.tableHeight = this.$refs.tabDiv.offsetHeight - 50 + ''
            this.$forceUpdate()
          })
          // this.totalPurchaseAmount = this.OpearateMoney(this.totalPurchaseAmount)
          // this.totalSaleAmount = this.OpearateMoney(this.totalSaleAmount)
          this.total = res.data.total
        }
      })
    },
    // 获取下拉列表
    getProductSelect() {
      this.$http.get(this.$apiConsign.productSkuList, { supplier_id: this.$route.query.supplier_id }).then(res => {
        if (res.status) {
          this.productSelect = res.data
        }
      })
    },
    // 在选择产品之后获取其他两项下拉框的值
    getOtherSelect(value) {
      this.otherSelect = []
      if (value) {
        let product_id = value
        this.productDisable = false
        this.$http.get(this.$apiConsign.productSkuList, { supplier_id: this.$route.query.supplier_id, product_id }, true).then(res => {
          if (res.status) {
            this.otherSelect = res.data
          }
        })
      } else {
        this.productDisable = true
      }
    },
    // 搜索项目
    searchItem() {
      let list = this.queryFrom.specification_id_str ? this.queryFrom.specification_id_str.join(',') : ''
      let searchForm = {}
      searchForm.page = this.pages.page
      searchForm.rows = this.pages.rows
      searchForm.supplier_id = this.supplier_id
      searchForm.product_model_code = this.queryFrom.product_model_code
      searchForm.product_id = this.queryFrom.product_id
      searchForm.model_id = this.queryFrom.code_id
      searchForm.item_number = this.queryFrom.item_number
      searchForm.specification_id_str = list
      this.getTableList(searchForm)
    },
    // 产品名称重选时，其他搜索项清空
    clearOtherItem() {
      this.queryFrom = {
        product_model_code: '',
        code_id: '',
        item_number: '',
      }
      this.otherSelect = []
    },
    // 返回
    goback() {
      this.$router.push('/supplierSupplyListConsign')
    },
    // 数字转金额处理
    formatMoney(totalNum) {
      let money = 0
      if (totalNum >= 0) {
        let num = totalNum
        num = parseFloat((num + '').replace(/[^\d\.-]/g, '')).toFixed(2) + ''
        let valueArr = num.split('.')[0].split('').reverse()
        const valueFloat = num.split('.')[1]
        let valueString = ''
        for (let i = 0; i < valueArr.length; i++) {
          valueString += valueArr[i] + ((i + 1) % 3 == 0 && i + 1 != valueArr.length ? ',' : '') // 循环 取数值并在每三位加个','
        }
        money = valueString.split('').reverse().join('') + '.' + valueFloat // 拼接上小数位
      } else {
        totalNum = Math.abs(totalNum)
        let num = totalNum
        num = parseFloat((num + '').replace(/[^\d\.-]/g, '')).toFixed(2) + ''
        let valueArr = num.split('.')[0].split('').reverse()
        const valueFloat = num.split('.')[1]
        let valueString = ''
        for (let i = 0; i < valueArr.length; i++) {
          valueString += valueArr[i] + ((i + 1) % 3 == 0 && i + 1 != valueArr.length ? ',' : '') // 循环 取数值并在每三位加个','
        }
        money = valueString.split('').reverse().join('') + '.' + valueFloat // 拼接上小数位
        money = '-' + money
      }
      return money
    },
  },
  created() {
    this.queryOption()
    let supplier_id = this.$route.query.supplier_id
    this.supplier_id = supplier_id
    let obj = { supplier_id, page: 1, rows: 10 }
    this.getTableList(obj)
    this.getProductSelect()
  },
}
</script>

<style scoped lang="less">
.home {
  display: flex;
  flex-direction: column;
}
.tabDiv {
  flex: 1;
  overflow-y: auto;
  height: 100%;
}
.marginTop20 {
  margin-top: 20px;
}
.table {
  /deep/ .ivu-table-summary {
    border-top: 0;
  }
}
</style>
